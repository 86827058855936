export const EXHIBITOR_CONTACT_BASE_FRAGMENT = `
  fragment exhibitorContactBaseFragment on ExhibitorContact {
    uid
    schemaCode
    type
    phone
    fax
    email
    name
  }
`;
